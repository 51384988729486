// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NWnmzyxzZqXyT8aXHehQ{text-align:center;color:#111;background:radial-gradient(circle at 100% 50%, transparent 20%, rgb(255 255 255/30%) 21%, rgb(255 255 255/30%) 34%, transparent 35%, transparent),radial-gradient(circle at 0% 50%, transparent 20%, rgb(255 255 255/30%) 21%, rgb(255 255 255/30%) 34%, transparent 35%, transparent) 0 -50px;background-color:slategray;background-size:75px 100px;box-shadow:0 0 50px 0 rgb(0 0 0/20%)}.NWnmzyxzZqXyT8aXHehQ h1{font-weight:bold;letter-spacing:.1em}.yMFmYW6gJhC6ptgUAq6M{padding:1rem;text-align:center}.I_ouwjmB_BmQL1FbFF1Z{text-align:center}`, "",{"version":3,"sources":["webpack://./src/App/App.module.css"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CACA,UAAA,CACA,8RACC,CAiBD,0BAAA,CACA,0BAAA,CACA,oCAAA,CAGD,yBACC,gBAAA,CACA,mBAAA,CAGD,sBACC,YAAA,CACA,iBAAA,CAGD,sBACC,iBAAA","sourceRoot":""}]);
// Exports
export var header = `NWnmzyxzZqXyT8aXHehQ`;
export var main = `yMFmYW6gJhC6ptgUAq6M`;
export var footer = `I_ouwjmB_BmQL1FbFF1Z`;
export default ___CSS_LOADER_EXPORT___;
