import React, { Suspense } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "../index.scss";
import styles from "./App.module.css";
const ViewContainer = React.lazy(() => import(
/* webpackChunkName: "view-container" */ "../components/ViewContainer/ViewContainer"));
function App() {
    return (React.createElement("div", { className: styles.App },
        React.createElement("header", { className: styles.header },
            React.createElement("h1", null, "\uD83E\uDE84CSV Magic\uD83E\uDE84")),
        React.createElement("main", { className: styles.main },
            React.createElement("p", null, "CSV Magic is a replacement for traditional spreadsheet software for tasks involving transforming data. This app takes a comma separated value file (.csv) and allows for sorting, filtering, and other large scale manipulations within one or multiple CSV files."),
            React.createElement("p", null, "The app runs and processes data entirely in your web browser with no spreadsheet data sent to the server."),
            React.createElement("p", null,
                React.createElement("a", { href: "https://github.com/mirite/csv-magic", target: "_blank", rel: "noreferrer" }, "View On GitHub")),
            React.createElement(Suspense, { fallback: React.createElement("div", null, "Loading...") },
                React.createElement(ViewContainer, null))),
        React.createElement("footer", { className: styles.footer },
            "Copyright \u00A9 ",
            new Date().getFullYear(),
            " ",
            React.createElement("a", { href: "https://jesseconner.ca", target: "_blank", rel: "noreferrer" }, "Jesse Conner"))));
}
export default App;
